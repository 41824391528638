/* color start*/

.text-EBF7FA {
  color: #ebf7fa !important;
}

.text-EAE9E3 {
  color: #eae9e3 !important;
}

.text-00B5D0 {
  color: #00b5d0 !important;
}

.text-00ADF7 {
  color: #00adf7 !important;
}

.text-F2F9FA {
  color: #f2f9fa !important;
}

.text-1DCA81 {
  color: #1dca81 !important;
}

.text-d9d9d9 {
  color: #d9d9d9 !important;
}

.text-ffa500 {
  color: #ffa500 !important;
}

.text-71C5CD {
  color: #71c5cd !important;
}

.text-C2E9F1 {
  color: #c2e9f1 !important;
}

.text-53C3DA {
  color: #53c3da !important;
}

.text-0B9ED9 {
  color: #0b9ed9 !important;
}

.text-FFFFFF {
  color: #ffffff !important;
}

.text-000000 {
  color: #000000 !important;
}

.text-FF9900 {
  color: #ff9900 !important;
}

.text-2D2D2D {
  color: #2d2d2d !important;
}

.text-transparent {
  color: transparent !important;
}

.text-FF4D4F {
  color: #ff4d4f !important;
}

.text-ED4337 {
  color: #ed4337 !important;
}

.text-EAE9E399 {
  color: #eae9e399 !important;
}

/* background */

.bg-121212,
.hover\:bg-121212:hover {
  background-color: #121212 !important;
}

.bg-121212A1 {
  background-color: #121212a1;
}

.bg-242626,
.hover\:bg-242626:hover {
  background-color: #242626 !important;
}

.bg-000000 {
  background-color: #000000 !important;
}

.bg-ED4337 {
  background-color: #ed4337 !important;
}

.bg-ffffff {
  background-color: #ffffff !important;
}

.bg-1BD37F {
  background-color: #1bd37f !important;
}

.bg-FBD127 {
  background-color: #fbd127 !important;
}

.bg-FF0000 {
  background-color: #ff0000 !important;
}

.bg-82d3e3 {
  background-color: #82d3e3 !important;
}

.bg-006899 {
  background-color: #006899 !important;
}

.hover\:bg-00B5D0:hover {
  background-color: #00b5d0 !important;
}

/* boder */
.border-121212 {
  border-color: #121212 !important;
}

.border-71C5CD {
  border-color: rgba(113, 197, 205, 0.5) !important;
}

.border-00B5D0 {
  border-color: #00b5d0 !important;
}

.border-000000 {
  border-color: #000000 !important;
}

.border-FFFFFF1A {
  border-color: #ffffff1a !important;
}

.border-EBF7FA {
  border-color: #ebf7fa !important;
}

.border-242626 {
  border-color: #242626 !important;
}

.border-006899 {
  border-color: #006899 !important;
}

.placeholder-EBF7FA::placeholder {
  color: #ebf7fa !important;
}

/* button */

.ant-btn-custom-default {
  &,
  &:focus {
    border-color: #00b5d0;
    color: #00b5d0;
    background-color: transparent !important;
  }

  &:hover {
    background-color: #00b5d0 !important;
    color: white;
  }

  &.ant-btn-dangerous {
    &,
    &:focus {
      color: #ff4d4f;
      border-color: #ff4d4f;
    }

    &:hover {
      background-color: #ff4d4f;
      color: white;
    }
  }

  &[disabled],
  &[disabled]:hover {
    border-color: #ebf7fa;
    color: #ebf7fa;
    opacity: 0.6;
    background: transparent !important;
  }
}

@media (min-width: 992px) {
  .bg-lg-121212 {
    background-color: #121212 !important;
  }
}

// other
.fill-transparent {
  svg {
    fill: transparent;
  }
}

// media
@media (min-width: 992px) {
  .bg-lg-121212 {
    background-color: #121212 !important;
  }

  .bg-lg-transparent {
    background-color: transparent !important;
  }

  .bg-lg-242626 {
    background-color: #242626 !important;
  }
}

/* outline */
.outline-0055d399,
.hover\:outline-0055d399:hover,
.focus\:outline-0055d399:focus {
  outline-color: #0055d399 !important;
}
