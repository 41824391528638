/* You can add global styles to this file, and also import other style files */
@import url(assets/css/bootstrap.css);
@import url(assets/css/font.css);
@import url(assets/css/base.css);
@import url(assets/css/color.scss);
// @import "../node_modules/ng-zorro-antd/ng-zorro-antd.variable.min.css";

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

.ant-form-item-label {
  > label {
    color: inherit !important;
    font-size: inherit !important;
    height: unset;
    width: 100%;
  }
}

nz-form-control.w-100 {
  .ant-form-item-control-input {
    width: 100%;
    min-height: unset;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected {
  background-color: #121212 !important;
  transition: all 5000s ease-in-out 0s !important;
  transition-property: background-color, color !important;
  -webkit-box-shadow: 0 0 0 1000px #121212 inset !important;
  -webkit-text-fill-color: #ebf7fa !important;
}

.ant-checkbox-inner {
  background-color: transparent;
  border-width: 2px;
  width: 18px;
  height: 18px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: rgba(0, 180, 208, 0.3) !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #00b5d0 !important;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: #82d3e3 !important;
  background: #ebf7fa !important;
  opacity: 0.5;
}

.ant-btn {
  &::before {
    background: transparent;
  }

  .anticon-loading {
    display: inline-flex;
  }
}

nz-notification {
  .ant-notification-notice {
    background-color: #121212;
    border-radius: 20px;
    box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.1);

    .ant-notification-notice-description,
    .ant-notification-notice-message {
      color: #ebf7fa;
    }
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #383838;
}

::-webkit-scrollbar-thumb {
  background: #00b5d0;
  border-radius: 100vh;
  border: 3px solid #00b5d0;
}

::-webkit-scrollbar-thumb:hover {
  background: #00b5d0 !important;
}

nz-alert {
  &.amates-warning-alert {
    .ant-alert {
      background-color: #fbd127;
      padding: 8px;
      border-radius: 4px;
      border: 0;
      text-align: left;

      .ant-alert-icon {
        margin-right: 6px;
      }

      .ant-alert-description {
        line-height: 13px;
      }

      .ant-alert-message {
        line-height: 17px;
      }

      .ant-alert-close-icon {
        .anticon {
          color: #000000;
        }
      }
    }
  }
}

nz-notification {
  .ant-notification-notice {
    .ant-notification-notice-close {
      color: #ffffff;
    }
  }
}

nz-skeleton-element {
  &.skeleton-h-12 {
    &.ant-skeleton-active,
    & .ant-skeleton-button {
      height: 12px;
    }
  }

  &.skeleton-h-16 {
    &.ant-skeleton-active,
    & .ant-skeleton-button {
      height: 16px;
    }
  }

  &.skeleton-h-18 {
    &.ant-skeleton-active,
    & .ant-skeleton-button {
      height: 18px;
    }
  }

  &.skeleton-h-22 {
    &.ant-skeleton-active,
    & .ant-skeleton-button {
      height: 22px;
    }
  }

  &.skeleton-h-24 {
    &.ant-skeleton-active,
    & .ant-skeleton-button {
      height: 24px;
    }
  }

  &.skeleton-h-28 {
    &.ant-skeleton-active,
    & .ant-skeleton-button {
      height: 28px;
    }
  }

  &.skeleton-h-42 {
    &.ant-skeleton-active,
    & .ant-skeleton-button {
      height: 42px;
    }
  }
}
