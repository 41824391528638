@font-face {
  font-family: AllRoundGothic-Medium;
  src: url("../fonts/AllRoundGothic-Medium.otf");
}

@font-face {
  font-family: Mundial-Bold;
  src: url("../fonts/Mundial-Bold.otf");
}

@font-face {
  font-family: Mundial-Light;
  src: url("../fonts/Mundial-Light.otf");
}

@font-face {
  font-family: OpenSans-Light;
  src: url("../fonts/OpenSans/OpenSans-Light.ttf");
}

@font-face {
  font-family: OpenSans-Medium;
  src: url("../fonts/OpenSans/OpenSans-Medium.ttf");
}

@font-face {
  font-family: OpenSans-Regular;
  src: url("../fonts/OpenSans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: OpenSans-SemiBold;
  src: url("../fonts/OpenSans/OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: OpenSans-Bold;
  src: url("../fonts/OpenSans/OpenSans-Bold.ttf");
}

/*------------css resets------------[start]*/

/* font start */

* {
  font-family: Mundial-Light !important;
}

.font-all-round-gothic-medium {
  font-family: AllRoundGothic-Medium !important;
}

.font-mundial-bold {
  &,
  * {
    font-family: Mundial-Bold !important;
  }
}

.font-mundial-light {
  font-family: Mundial-Light !important;
}

.font-openSans-light {
  font-family: OpenSans-Light !important;
}

.font-openSans-medium {
  font-family: OpenSans-Medium !important;
}

.font-openSans-semiBold {
  font-family: OpenSans-SemiBold !important;
}

.font-openSans-regular {
  font-family: OpenSans-Regular !important;
}

.font-openSans-bold {
  font-family: OpenSans-Bold !important;
}

.font-6 {
  font-size: 6px !important;
}

.font-8 {
  font-size: 8px !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-11 {
  font-size: 11px !important;
  line-height: 13px;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
  line-height: 17px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-19 {
  font-size: 19px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-28 {
  font-size: 28px !important;
}

.font-30 {
  font-size: 30px !important;
}

.font-32 {
  font-size: 32px !important;
}

.font-36 {
  font-size: 36px !important;
}

.font-40 {
  font-size: 40px !important;
}

.font-42 {
  font-size: 42px !important;
}

.font-48 {
  font-size: 48px !important;
}

.font-58 {
  font-size: 58px !important;
}

.font-70 {
  font-size: 70px !important;
}

.font-85 {
  font-size: 85px !important;
}

.font-140 {
  font-size: 140px !important;
}

.font-bold {
  font-weight: bold !important;
}

.placeholder-f-14::placeholder {
  font-size: 14px;
}

.placeholder-ls-normal::placeholder {
  letter-spacing: normal;
}

.placeholer-center::placeholder {
  text-align: center;
}

:focus-visible {
  outline: 0 !important;
}

@media (max-width: 1880px) {
  .font-xxxl-8 {
    font-size: 8px !important;
  }

  .font-xxxl-10 {
    font-size: 10px !important;
  }

  .font-xxxl-12 {
    font-size: 12px !important;
  }

  .font-xxxl-14 {
    font-size: 14px !important;
  }

  .font-xxxl-16 {
    font-size: 16px !important;
  }

  .font-xxxl-24 {
    font-size: 24px !important;
  }

  .font-xxxl-28 {
    font-size: 28px !important;
  }
}

@media (max-width: 1660px) {
  .font-xxl-8 {
    font-size: 8px !important;
  }

  .font-xxl-10 {
    font-size: 10px !important;
  }

  .font-xxl-14 {
    font-size: 14px !important;
  }

  .font-xxl-16 {
    font-size: 16px !important;
  }

  .font-xxl-24 {
    font-size: 24px !important;
  }

  .font-xxl-28 {
    font-size: 28px !important;
  }
}

@media (max-width: 992px) {
  .font-lg-24 {
    font-size: 24px !important;
  }

  .font-lg-16 {
    font-size: 16px !important;
  }

  .font-lg-12 {
    font-size: 12px !important;
  }
}

@media (min-width: 768px) {
  .font-md-70 {
    font-size: 70px !important;
  }

  .font-md-19 {
    font-size: 19px !important;
  }
}

@media (max-width: 550px) {
  .font-xs-8 {
    font-size: 8px !important;
  }

  .font-xs-10 {
    font-size: 10px !important;
  }

  .font-xs-14 {
    font-size: 14px !important;
  }

  .font-xs-16 {
    font-size: 16px !important;
  }

  .font-xs-24 {
    font-size: 24px !important;
  }

  .font-xs-28 {
    font-size: 28px !important;
  }
}

/* font end */
